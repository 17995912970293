import React, { Component } from "react";

class Credits extends Component {
  render() {
    return (
      <section className="credits">
        <p className="credits__title"> Credits</p>

        <div className="credits__details">
          <span>
            <p className="credits__details__title">Creative Direction</p>
            <p>
              <a href="https://www.anrk.co/" target="_blank">
                Studio ANRK
              </a>
            </p>
          </span>

          <span>
            <p className="credits__details__title">Design and Development</p>
            <p>
              <a href="https://www.rollstudio.co.uk" target="_blank">
                Roll Studio
              </a>
            </p>
          </span>

          <span>
            <p className="credits__details__title">3D Artist</p>
            <p>
              <a className="interaction-disabled">
                Stanislav Shcherbakov
              </a>
            </p>
          </span>

          <span>
            <p className="credits__details__title">Rendering</p>
            <p>
              <a className="interaction-disabled">
                Giuseppe Motta
              </a>
            </p>
          </span>


          <span>
            <p className="credits__details__title">Texturing</p>
            <p>
              <a className="interaction-disabled">
                Siz Sarayut
              </a>
            </p>
          </span>

          <span>
            <p className="credits__details__title">Rigging and Animations</p>
            <p>
              <a className="interaction-disabled">
                Eddie Chew
              </a>
              ,{" "}
              <a className="interaction-disabled">
                Bachir Kazzi
              </a>
              ,{" "}
              <a className="interaction-disabled">
                Hakob Muradian
              </a>
            </p>
          </span>

          <span>
            <p className="credits__details__title">Website Development</p>
            <p>
              <a href="https://www.linkedin.com/in/serena-falchieri/ " target="_blank">
                Serena Falchieri
              </a>
            </p>
          </span>

          <span>
            <p className="credits__details__title">Social Filters</p>
            <p>
              <a className="interaction-disabled">
                Askhat Mudarisov
              </a>
            </p>
          </span>

          <span>
            <p className="credits__details__title">6d Prototyping</p>
            <p>
              <a className="interaction-disabled">
                Herman Fredlund
              </a>
            </p>
          </span>
        </div>
      </section>
    );
  }
}

export default Credits;
