import React from "react";
import classnames from "classnames";

const Phone = ({ className, children }) => (
  <div className={classnames("phone", className)}>
    <canvas width="367" height="715" />
    <div className="phone__inner">{children}</div>
  </div>
);

export default Phone;
