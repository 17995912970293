import React, { Component } from "react";
import Slider from "./slider";

class Features extends Component {
  constructor(props) {
    super(props);
    this.browsePictures = this.browsePictures.bind(this);

    this.card1 = null;
    this.card2 = null;
    this.card3 = null;

    this.state = {
      index: 2,
      isFirst: false
    };
  }

  // function to make the pictures switch place
  browsePictures(e) {
    e.stopPropagation();
    let i =
      this.state.index < 2 ? (this.state.index += 1) : (this.state.index = 0);
    this.setState({ index: i }, this.updateCarousel);
  }

  updateCarousel = () => {
    switch (this.state.index) {
      case 0:
        this.card1.classList.add("first");
        this.card1.classList.remove("second", "third");
        this.card2.classList.add("second");
        this.card2.classList.remove("third", "first");
        this.card3.classList.add("third");
        this.card3.classList.remove("first", "second");
        break;

      case 1:
        this.card1.classList.add("third");
        this.card1.classList.remove("first", "second");
        this.card2.classList.add("first");
        this.card2.classList.remove("second", "third");
        this.card3.classList.add("second");
        this.card3.classList.remove("third", "first");
        break;

      case 2:
        this.card1.classList.add("second");
        this.card1.classList.remove("third", "first");
        this.card2.classList.add("third");
        this.card2.classList.remove("first", "second");
        this.card3.classList.add("first");
        this.card3.classList.remove("second", "third");
        break;

      default:
        break;
    }
  };

  onTouchStart = e => {
    this.x0 = e.touches[0].clientX;
  };

  onTouchMove = e => {
    const x0 = this.x0;

    if (x0 === null) {
      return;
    }

    this.x = e.touches[0].clientX;
  };

  onTouchEnd = () => {
    let index = this.state.index;
    const dX = this.x0 - this.x;

    index = dX > 0 ? index + 1 : index - 1;
    index = this.mod(index, 3);

    this.updateIndex(index);
  };

  onMouseDown = e => {
    this.x0 = e.clientX;
  };

  onMouseMove = e => {
    const x0 = this.x0;

    if (x0 === null) {
      return;
    }

    this.x = e.clientX;
  };

  onMouseUp = () => {
    let index = this.state.index;
    const dX = this.x0 - this.x;

    index = dX > 0 ? index + 1 : index - 1;
    index = this.mod(index, 3);

    this.updateIndex(index);
  };

  mod(n, m) {
    return ((n % m) + m) % m;
  }

  updateIndex = index => {
    this.setState(
      {
        index: index
      },
      this.updateCarousel
    );
  };

  render() {
    const { index } = this.state;

    return (
      <section className="section features" id="technology">
        <div className="technical_features">
          <img
            className="title"
            alt="Technical Features"
            src={require("../assets/images/features_title.svg")}
          />

          {/*<p>
            <span className="p-desktop">
              Built with 6D Reality Platform
              <br />
              AR With Depth, Occlusion and <br />
              Physics
            </span>

            <span className="p-mobile">
              Built with 6D Reality Platform AR With
              <br />
              Depth, Occlusion and Physics
            </span>
          </p>*/}

          <Slider
            newIndex={index}
            onIndexChange={this.updateIndex}
            sliderConstant="--slider-constant--1"
            items={[
              {
                title: "Technology",
                text:
                  "The technology behind Bosch is built on top of ARKit 3, but is powered by 6D.ai’s depth " +
                  "mapping technology, allowing the characters to live in your space and literally disappear " +
                  "behind real objects. ",
                badge: true
              },
              {
                title: "Technology",
                text:
                  "Occlusion is a powerful way to add a new level of realism to AR. 6D’s technology layers scans" +
                  " your data locally, and uses that to hide our characters in a realistic way."
              },
              {
                title: "The Future of this App",
                text:
                  "We’re exploring adding Magic Leap support and Wayfinding feature in v. 2.0. We’d love to " +
                  "add more characters and look into additional interactions between them."
              }
            ]}
          />
        </div>

        <div className="slide_gallery">
          <div
            className="stack"
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
          >
            <div
              className="card card1 first"
              ref={c => {
                this.card1 = c;
              }}
            ></div>

            <div
              className="card card2 second"
              ref={c => {
                this.card2 = c;
              }}
            ></div>

            <div
              className="card card3 third"
              ref={c => {
                this.card3 = c;
              }}
            ></div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
