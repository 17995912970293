import React from "react";
import { CSSTransition } from "react-transition-group";

// components
import Loading from "./components/loading";
import Header from "./components/header";
import MOBILEscreensSection from "./components/mobileScreens/MOBILEscreensSection";
import ScreensSection from "./components/mobileScreens/screensSection";
import MOBILEfirstSection from "./components/mobileScreens/MOBILEfirstSection";
import MeetTheCharacters from "./components/meetTheCharacters";
import About from "./components/about";
import Features from "./components/features";
import Slider from "./components/slider";
import Download from "./components/download";
import Credits from "./components/credits";
import Footer from "./components/footer";
import CookiesBanner from "./components/cookiesBanner";

// css
import "./assets/scss/default.scss";
import "./assets/scss/typography.scss";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  /* React lifecycle */
  // componentDidMount() {
  //   // CHECK: why?
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 800);
  // }

  // componentDidUpdate() {
  //   // CHECK: still needed?
  //   console.log(this.state.isAnimationOver);
  //   // if animation is over, then count 3s before going to next component
  //   if (this.state.isAnimationOver && this.state.isLoading) {
  //     setTimeout(
  //       function() {
  //         this.setState({ isLoading: false });
  //       }.bind(this),
  //       2000
  //     );
  //   }
  // }

  /* Event handlers */
  handleLoadComplete = () => {
    // we let the transition complete
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);
  };

  /* Render */
  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div className="App">
          <CSSTransition
            in={isLoading}
            timeout={{
              exit: 1000
            }}
            unmountOnExit
            classNames="loading-"
          >
            <Loading onLoadComplete={this.handleLoadComplete} />
          </CSSTransition>

          <CSSTransition
            in={!isLoading}
            appear
            timeout={{
              appear: 2000,
              enter: 2000,
              exit: 500
            }}
            mountOnEnter
            unmountOnExit
            classNames="header-"
          >
            <Header />
          </CSSTransition>

          <CSSTransition
            in={!isLoading}
            appear
            timeout={{
              appear: 2500,
              enter: 2500,
              exit: 1000
            }}
            mountOnEnter
            unmountOnExit
            classNames="screens_first_section-"
          >
            <ScreensSection />
          </CSSTransition>
          <CSSTransition
            in={!isLoading}
            appear
            timeout={{
              appear: 2500,
              enter: 2500,
              exit: 1000
            }}
            mountOnEnter
            unmountOnExit
            classNames="screens_first_mobile_only-"
          >
            <MOBILEfirstSection />
          </CSSTransition>

          {!isLoading && (
            <>
              <MOBILEscreensSection />
              <About />
              <MeetTheCharacters />
              <Features />
              <Slider
                items={[
                  {
                    text:
                      "It’s so cool, loved playing with it. It’s like Pokémon Go but the characters interact with me & their environment",
                    author: "Karen"
                  },
                  {
                    text:
                      "It was really exciting seeing the characters come to life in the space. I loved it when Flute walked down the stairs!",
                    author: "Jasmine"
                  },
                  {
                    text:
                      "LOL, how funny is seeing the Sea horse playing with my dog?",
                    author: "Stephanie"
                  }
                ]}
              />
              <Download />
              <Credits />
              <CookiesBanner />
              <Footer />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default App;
