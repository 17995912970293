import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { thisExpression } from "@babel/types";

class MeetTheCharacters extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }
  state = {
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0
  };

  /* used to prevent vertical scrolling while swiping on the carousel in iOS */
  componentDidMount() {
    this.containerRef.addEventListener("touchstart", this.touchStart);
    this.containerRef.addEventListener("touchmove", this.preventTouch, {
      passive: false
    });
  }

  componentWillUnmount() {
    this.containerRef.removeEventListener("touchstart", this.touchStart);
    this.containerRef.removeEventListener("touchmove", this.preventTouch, {
      passive: false
    });
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e) {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      //e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }
  /* ------------- */

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: false,
      // check index of displayed image
      beforeChange: (current, next) =>
        this.setState({
          oldSlide: current,
          activeSlide: next
        }),
      afterChange: current => this.setState({ activeSlide2: current })
    };

    return (
      <div
        className="carousel_section"
        id="#carousel"
        ref={ref => (this.containerRef = ref)}
      >
        <img
          className="title"
          alt="Meet the characters"
          src={require("../assets/images/meet_title.svg")}
        ></img>
        <div className="carousel_container">
          <div
            style={{ opacity: this.state.activeSlide === 0 ? 1 : 0 }}
            className={"carousel_char_1"}
          ></div>
          <div
            style={{ opacity: this.state.activeSlide === 1 ? 1 : 0 }}
            className={"carousel_char_2"}
          ></div>
          <div
            style={{ opacity: this.state.activeSlide === 2 ? 1 : 0 }}
            className={"carousel_char_3"}
          ></div>
          <div
            style={{ opacity: this.state.activeSlide === 3 ? 1 : 0 }}
            className={"carousel_char_4"}
          ></div>
          <div
            style={{ opacity: this.state.activeSlide === 4 ? 1 : 0 }}
            className={"carousel_char_5"}
          ></div>
          <Slider {...settings}>
            <div className="slide">
              <img src={require("../assets/images/slide_1_zoom.png")}></img>
              <div className="slide__filter"></div>
              <img className="slide__detail slide__detail--1" src={require("../assets/images/slide_1_zoom__detail.png")}></img>
            </div>
            <div className="slide">
              <img src={require("../assets/images/slide_2_zoom.png")}></img>
              <div className="slide__filter"></div>
              <img className="slide__detail slide__detail--2" src={require("../assets/images/slide_2_zoom__detail.png")}></img>
            </div>
            <div className="slide">
              <img src={require("../assets/images/slide_3_zoom.png")}></img>
              <div className="slide__filter"></div>
              <img className="slide__detail slide__detail--3" src={require("../assets/images/slide_3_zoom__detail.png")}></img>
            </div>
            <div className="slide">
              <img src={require("../assets/images/slide_4_zoom.png")}></img>
              <div className="slide__filter"></div>
              <img className="slide__detail slide__detail--4" src={require("../assets/images/slide_4_zoom__detail.png")}></img>
            </div>
            <div className="slide">
              <img src={require("../assets/images/slide_5_zoom.png")}></img>
              <div className="slide__filter"></div>
              <img className="slide__detail slide__detail--5" src={require("../assets/images/slide_5_zoom__detail.png")}></img>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default MeetTheCharacters;
