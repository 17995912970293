import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footer__logo">
          <img
            src={require("../assets/images/footer_title.svg")}
            alt="Bosch logo"
          />

          <p>
            Liked the project?&nbsp;
            <a href="mailto:hello@rollstudio.co.uk">Drop us a line</a>
          </p>
        </div>

        <div className="footer__actions">
          <span className="copyright">&copy; 2020 Bosch</span>
        </div>
      </footer>
    );
  }
}

export default Footer;
