import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="section about" id="about">
        <div className="about_text">
          <img
            className="title"
            alt="About"
            src={require("../assets/images/about_title.svg")}
          ></img>

          <h2 className="title2">
            Bring Bosch<span className="apostrophe">'</span>s art <br />
            out of the canvas
          </h2>

          <p className="p-desktop">
            We love the beautiful, haunting work of Hieronymus Bosch, and were
            inspired to create something for the annual Bosch Parade, in his
            hometown Den Bosch.
            <br />
            <br />
            We decided to do this through the Bosch AR app. We wanted to create
            the technology for anyone with a smartphone to bring to life some of
            Bosch’s characters, painted circa 1500, into the world around them.
            <br />
            <br />
            To do this, we built an Augmented Reality experience for your
            smartphone, which allows you to pick from 5 Bosch characters, and
            bring them into your world. If you decide to select all 5 characters
            in to your environment, you can create a fantastic Bosch parade all
            of your own.
          </p>

          <p className="p-mobile">
            We love the beautiful, haunting work of Hieronymus Bosch, and were
            inspired to create something for the annual Bosch Parade, in his
            hometown Den Bosch.
            <br />
            <br />
            We decided to do this through the Bosch AR app. We wanted to create
            the technology for anyone with a smartphone to bring to life some of
            Bosch’s characters, painted circa 1500, into the world around them.
            <br />
            <br />
            To do this, we built an Augmented Reality experience for your
            smartphone, which allows you to pick from 5 Bosch characters, and
            bring them into your world. If you decide to select all 5 characters
            in to your environment, you can create a fantastic Bosch parade all
            of your own.
          </p>
        </div>
        <div className="pic_container">
          <img src={require("../assets/images/bosch_garden.png")} />
        </div>
      </div>
    );
  }
}

export default About;
