import React, { Component } from "react";
import classnames from "classnames";

export default class Slider extends Component {
  static defaultProps = {
    sliderConstant: "--slider-constant",
    onIndexChange: () => null,
    newIndex: null,
  };

  state = { index: 0 };

  x0 = null;
  x = 0;

  constructor (props) {
    super(props);

    document.documentElement.style.setProperty(this.props.sliderConstant, 0);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { index } = this.state;
    const { sliderConstant } = this.props;

    if (nextProps.newIndex !== index) {
      this.setState({
        index: nextProps.newIndex,
      });

      document.documentElement.style.setProperty(sliderConstant, nextProps.newIndex);
    }
  }

  onSliderControllerClick = index => {
    const { sliderConstant, onIndexChange } = this.props;
    this.setState({ index: index }, onIndexChange(index));

    document.documentElement.style.setProperty(sliderConstant, index);
  };

  onTouchStart = e => {
    this.x0 = e.touches[0].clientX;
  };

  onTouchMove = e => {
    const x0 = this.x0;

    if (x0 === null) {
      return;
    }

    this.x = e.touches[0].clientX;
  };

  onTouchEnd = () => {
    const { sliderConstant, items, onIndexChange } = this.props;
    let index = this.state.index;
    const dX = this.x0 - this.x;

    index = dX > 0 ? index + 1 : index - 1;
    index = this.mod(index, items.length);

    this.x0 = null;
    this.setState({ index: index }, function() {
      document.documentElement.style.setProperty(sliderConstant, index);
      onIndexChange(index);
    });
  };

  onMouseDown = e => {
    this.x0 = e.clientX;
  };

  onMouseMove = e => {
    const x0 = this.x0;

    if (x0 === null) {
      return;
    }

    this.x = e.clientX;
  };

  onMouseUp = () => {
    const { sliderConstant, items, onIndexChange } = this.props;
    let index = this.state.index;
    const dX = this.x0 - this.x;

    index = dX > 0 ? index + 1 : index - 1;
    index = this.mod(index, items.length);

    this.x0 = null;
    this.setState({ index: index }, function() {
      document.documentElement.style.setProperty(sliderConstant, index);
      onIndexChange(index);
    });
  };

  mod(n, m) {
    return ((n % m) + m) % m;
  }

  render() {
    const { index } = this.state;
    const { items } = this.props;

    return (
      <div
        className="slider"
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
      >
        <ul className="slider-content">
          {items.map((item, i) => (
            <li className={i === index ? "active" : null} key={i}>
              <p>{item.text}</p>
              {item.author && <div className="slider__author">{item.author}</div>}
              {item.badge && <div className="slider__badge"></div>}
            </li>
          ))}
        </ul>

        <ul className="slider-controller">
          {items.map((item, i) => (
            <li key={i}>
              <a
                className={i === index ? "active" : null}
                onClick={this.onSliderControllerClick.bind(this, i)}
              ></a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
