import React, { useRef, useEffect, useCallback } from "react";
import ease from 'eases/quint-in-out';

const downloadBoshLogo = require("../assets/images/download_bosch.svg");
const downloadDeviceTitle = require("../assets/images/download_device_title.svg");
const transitionDuration = 1600;

const Loading = ({ onLoadComplete }) => {
  const progress = useRef(0);
  const loadTimeout = useRef(null);
  const overlayRef = useRef(null);
  const progressRef = useRef(null);
  const animationStartTime = useRef(null);

  const load = useCallback((t) => {
    if (animationStartTime.current === null) {
      animationStartTime.current = t;
    }
    const dT = t - animationStartTime.current;
    const p = ease(Math.min(1, dT / transitionDuration));

    // simulate progress
    progress.current = p;

    if (overlayRef.current && progressRef.current) {
      overlayRef.current.style.height = `${(1 - progress.current) * 100}%`;
      progressRef.current.innerText = `${Math.round(progress.current * 100)}%`;
    }

    if (progress.current < 1) {
      requestAnimationFrame(load);
    } else {
      onLoadComplete();
    }
  }, []);

  useEffect(() => {
    // CHECK: why?
    document.body.className = "loading-component-body";

    progress.current = 0;

    requestAnimationFrame(load);

    return () => {
      if (loadTimeout.current) {
        loadTimeout.current = clearTimeout(loadTimeout.current);
      }
    };
  }, [load]);

  return (
    <div className="loading">
      <div ref={overlayRef} className="opacity_layer"></div>

      <div className="title">
        <img alt="Bosch logo" src={downloadBoshLogo} />
        <img
          className="subtitle"
          alt="An AR experiment"
          src={downloadDeviceTitle}
        />
      </div>

      <div ref={progressRef} className="loading__progress">
        0%
      </div>
    </div>
  );
};

export default Loading;
