import React, { Component } from "react";

class Download extends Component {
  render() {
    return (
      <section className="download">
        <div className="download__claim">
          <img
              className="title"
              alt="Bosch AR experiment"
              src={require("../assets/images/download_title.svg")}
          />

          <img
              className="text"
              alt="Download on the App Store"
              src={require("../assets/images/download_text.svg")}
          />

          <a href="https://apps.apple.com/gb/app/bosch-ar/id1499809874?mt=8" target="_blank" className="appstore_badge">
            <img
                src={require("../assets/images/appstore_badge.svg")}
                alt="Link to the App Store"
            />
          </a>
        </div>

        <div className="download__device">
          <img
              className="download__device__title"
              alt="Download it on the App Store"
              src={require("../assets/images/download_device_title.svg")}
          />

          <img
              className="download__device__bosch"
              src={require("../assets/images/download_bosch.svg")}
              alt="Bosch logo"
          />

          <img
              className="download__device__square"
              alt="Bosch logo"
              src={require("../assets/images/download_B_square.svg")}
          />

          <img
              className="download__device__bosch-character"
              src={require("../assets/images/character2.svg")}
          />
        </div>
        {
          /*<div className="black_block">
            <div className="download_content">
              <div className="left_content">
                <img
                  className="title"
                  alt="Bosch AR experiment"
                  src={require("../assets/images/download_title.svg")}
                ></img>
                <img
                  className="text"
                  alt="Download on the App Store"
                  src={require("../assets/images/download_text.svg")}
                ></img>
                <a href="/" target="_blank" className="appstore_badge">
                  <img
                    src={require("../assets/images/appstore_badge.svg")}
                    alt="Link to the App Store"
                  />
                </a>
              </div>
              <div className="device">
                <div className="device_frame">
                  <img
                    className="title2"
                    alt="Download it on the App Store"
                    src={require("../assets/images/download_device_title.svg")}
                  ></img>
                  <div className="bosch_letters">
                    <img
                      src={require("../assets/images/download_bosch.svg")}
                      alt="Bosch logo"
                    ></img>
                  </div>
                </div>
                <img
                  className="bosch_square"
                  alt="Bosch logo"
                  src={require("../assets/images/download_B_square.svg")}
                ></img>

                <div className="bosch_character"></div>
              </div>
            </div>
          </div>*/
        }
      </section>
    );
  }
}

export default Download;
