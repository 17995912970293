import React, { Component } from "react";
import classnames from "classnames";

class CookiesBanner extends Component {
  state = {
    hidden: false,
    unmount: false
  };

  componentWillMount() {
    const cookies = window.localStorage.getItem("cookies");
    if (cookies) {
      this.setState({
        unmount: true
      });
    }
  }

  hide = () => {
    this.setState({
      hidden: true
    });
  };

  transitionEnded = e => {
    if (e.target !== e.currentTarget) {
      e.stopPropagation();
      return;
    }

    this.setState({
      unmount: true
    });
  };

  render() {
    const { hidden, unmount } = this.state;

    const cookiesBannerClasses = classnames("cookies-banner", {
      "cookies-banner--hidden": hidden,
      "cookies-banner--unmounted": unmount
    });

    return (
      <div
        className={cookiesBannerClasses}
        onTransitionEnd={this.transitionEnded}
      >
        <span>
          We are using cookies to improve your experience on this website.
        </span>

        <a
          href="#"
          onClick={e => {
            e.preventDefault();

            window.localStorage.setItem("cookies", true);
            this.hide();
          }}
        >
          Don't allow
        </a>

        <a
          href="#"
          onClick={e => {
            e.preventDefault();

            window.localStorage.setItem("cookies", true);
            this.hide();
          }}
        >
          Ok
        </a>
      </div>
    );
  }
}

export default CookiesBanner;
