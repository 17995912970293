import React, { Component } from "react";
import classnames from "classnames";

import Phone from "../phone";

const scrollLines = require("../../assets/images/scroll_lines.svg");
const scrollLearnMore = require("../../assets/images/scroll_learn_more.svg");

class MOBILEfirstSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: null,
      tappedOpacity: null,
      isTransitionOver: null,
      isAnimationOver: null,
        scrollInviteOpacity: 0
    };
  }

  componentDidMount() {
      document.body.className = "first-component-body";
      window.addEventListener("scroll", this.scrollingContainer);
  }

  componentWillUnmount() {
      window.removeEventListener("scroll", this.scrollingContainer);
  }

    scrollingContainer = () => {
        let firstTop = window.scrollY;

        if (firstTop < 0) {

        } else if (firstTop > 0) {
            this.setState({
                scrollInviteOpacity: 0
            });
        }
    };

  handlePanels = isTransitionOver => this.setState({ isTransitionOver: true });

  panelsOver = isAnimationOver => this.setState({ isAnimationOver: true });

  render() {
    const { isTransitionOver } = this.state;

    return (
      <section className="section screens_first_mobile_only" id="discover">
        <div className="banner_image">
          <div
            className={classnames(
              "banner_image__panel",
              "banner_image__panel--up",
              {
                "banner_image__panel--closed": isTransitionOver
              }
            )}
            onTransitionEnd={isTransitionOver ? this.panelsOver : null}
          ></div>

          <div
            className={classnames(
              "banner_image__panel",
              "banner_image__panel--down",
              {
                "banner_image__panel--closed": isTransitionOver
              }
            )}
          ></div>
        </div>

        <Phone className="device">
          <img
            className="title"
            alt="Bosch AR experiment"
            src={require("../../assets/images/screens_device_title.svg")}
          />

          <img
            className="tap_to_start"
            alt="Tap to start"
            style={{ opacity: this.state.opacity }}
            src={require("../../assets/images/tap_to_start.svg")}
          />
          <div
            className={"tap_invite"}
            style={{ opacity: this.state.opacity }}
            onClick={() => this.setState({ opacity: 0, tappedOpacity: 1, scrollInviteOpacity: 1 })}
            onTransitionEnd={this.handlePanels}
          >
            <div className="white_dot"></div>
            <div className="circle one"></div>
            <div className="circle two"></div>
            <div className="circle three"></div>
          </div>

          <img
            className="place_a_character"
            alt="Place a character"
            style={{ opacity: this.state.opacity }}
            src={require("../../assets/images/place_a_character.svg")}
          />
          {/* elements that appear after the user taps */}
          <img
            className="enjoy"
            alt="Enjoy!"
            style={{ opacity: this.state.tappedOpacity }}
            src={require("../../assets/images/enjoy.svg")}
          />
          <img
            className="character"
            alt="A character coming from Bosch's art, placed in the real world"
            style={{ opacity: this.state.tappedOpacity }}
            src={require("../../assets/images/bosch_character.svg")}
          />
        </Phone>

        <div
          className="scroll_invite"
          style={{
            opacity: this.state.scrollInviteOpacity
          }}
        >
          <img alt="Scroll down" src={scrollLines} />
          <img
            className="learn_more"
            alt="Scroll to learn more"
            src={scrollLearnMore}
          />
        </div>
      </section>
    );
  }
}

export default MOBILEfirstSection;
