import React, { Component } from "react";

import Phone from "../phone";

class MOBILEscreensSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: null,
      tappedOpacity: null
    };
  }

  render() {
    return (
      <div className="mobile_only">
        <div className="container">
          <img
            className="title"
            alt="Bosch AR experiment"
            src={require("../../assets/images/screens_sx1_title.svg")}
          />

          <h1 className="title2">Bring Bosch's art out of the canvas</h1>

          <Phone className="device1">
            <img
              className="navbar"
              alt="App title and menu icon"
              src={require("../../assets/images/device_navbar.svg")}
            />
            <img
              className="lower"
              alt="Buttons and controls within the app"
              src={require("../../assets/images/device_lower.svg")}
            />
            {/* elements that will change on scroll */}
            <img
              className="scanning"
              alt="Scanning"
              src={require("../../assets/images/scanning.svg")}
            />
            <div className="red_grid"></div>
          </Phone>

          <p className="desc1">Scan the environment around you.</p>

          {/* second screen */}

          <Phone className="device2">
            <img
              className="navbar"
              alt="App title and a menu icon"
              src={require("../../assets/images/device_navbar.svg")}
            />
            <img
              className="lower"
              alt="Buttons and controls whithin the app"
              src={require("../../assets/images/device_lower.svg")}
            />
            {/* elements that will change on scroll */}
            <img
              className="three_chars"
              alt="Three characters coming from Bosch's art, placed inside the real world"
              src={require("../../assets/images/three_chars_1.svg")}
            />
            <div className="scrollable">
              <div className="dots">
                <img src={require("../../assets/images/flute.svg")} />
                <img src={require("../../assets/images/bird.svg")} />
                <img src={require("../../assets/images/knight.svg")} />
                <img src={require("../../assets/images/seahorse.svg")} />
                <img src={require("../../assets/images/salamander.svg")} />
              </div>
            </div>
          </Phone>

          <p className="desc2">
            Change 'Choose between 5 characters and place them' to: Choose
            between 5 different characters and bring them to life around you.
          </p>

          <Phone className="device3">
            <img
              className="navbar"
              alt="App title and a menu icon"
              src={require("../../assets/images/device_navbar.svg")}
            />
            <img
              className="lower"
              alt="Buttons and controls whithin the app"
              src={require("../../assets/images/device_lower.svg")}
            />
            {/* elements that will change on scroll */}
            <img
              className="character1"
              alt="A character coming from Bosch's art, placed inside the real world"
              src={require("../../assets/images/character1.svg")}
            />

            <div className="scrollable2">
              <div className="dots2">
                <img src={require("../../assets/images/flute.svg")} />
                <img src={require("../../assets/images/bird.svg")} />
                <img src={require("../../assets/images/knight.svg")} />
                <img src={require("../../assets/images/seahorse.svg")} />
                <img src={require("../../assets/images/salamander.svg")} />
              </div>
            </div>
          </Phone>

          <p className="desc3">
            Interact with the characters by changing their size, position and
            orientation.
          </p>

          <Phone className="device4">
            <img
              className="cancel_button"
              alt="Button to cancel"
              src={require("../../assets/images/cancel_button.svg")}
            />
            <img
              className="character2"
              alt="A character coming from Bosch's art, placed inside the real world"
              src={require("../../assets/images/character2.svg")}
            />
            <img
              className="share_buttons"
              alt="Buttons to share"
              src={require("../../assets/images/share_buttons.svg")}
            />
          </Phone>

          <p className="desc4">
            Take pictures and recordings of your experience and share them with
            your friends!
          </p>
        </div>
      </div>
    );
  }
}

export default MOBILEscreensSection;
