import React, { Component } from "react";
import { Link } from "react-scroll";
import classnames from "classnames";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: false,
      isHidden: false,
      lastScrollY: 0, // keep track of current position in state
      discover: 0,
      about: 0,
      technology: 0,
      newSection: null
    };

    const sectionObserverOptions = {
      threshold: 0.00001,
    };

    this.sectionObserver = new IntersectionObserver(this.sectionObserverCallback, sectionObserverOptions);
  }

  componentDidMount() {
    // specify className for the component body -
    // in order to avoid conflicts with the loading page
    document.body.className = "app-component-body";
    this.setState({ discover: 1 });

    let sections = document.querySelectorAll(".section");
    sections.forEach(section => {
      this.sectionObserver.observe(section);
    })
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  sectionObserverCallback = (entries) => {
    entries.forEach(entry => {
      console.log(entry);
      if (entry.isIntersecting) {
        this.setState({
          discover: entry.target.id === "discover" ? 1 : 0,
          about: entry.target.id === "about" ? 1 : 0,
          technology: entry.target.id === "technology" ? 1 : 0,
        });
      }
    })
  };

  handleScroll = () => {
    const { lastScrollY } = this.state;
    const currentScrollY = window.scrollY;

    if (!this.state.newSection) {
      if (currentScrollY > lastScrollY) {
        this.setState({ isHidden: true });
      } else {
        this.setState({ isHidden: false });
      }
      this.setState({ lastScrollY: currentScrollY });
    }
  };

  // execute two functions onClick
  handleClick = () => {
    this.handleHeader();
    this.canScroll();
  };

  //prevents header from sliding up when anchor is reached
  handleHeader = () => {
    this.setState({ newSection: true });
    console.log(this.state.newSection);
    setTimeout(
      function() {
        this.setState({ newSection: null });
      }.bind(this),
      600
    );
  };

  // enable scrolling if user moves to another section of the page
  // without completing the tap to start animations
  canScroll() {
    document.body.className = "first-component-body-canScroll";
  }

  render() {
    const { className } = this.props;
    const { isHidden, offset } = this.state;

    return (
      <header
        className={classnames(
          "header",
          {
            "header--hidden": isHidden
          },
          className
        )}
      >
        <div className="header_content">
          <a href="/" className="menu_logo">
            <img
              alt="Website logo"
              src={require("../assets/images/menu_logo.svg")}
            />
          </a>
          <nav>
            <ul>
              <li>
                <Link
                  activeClass="xx"
                  to="discover"
                  spy={true}
                  smooth={true}
                  offset={-112}
                  duration={500}
                  className={this.state.discover == 1 ? "active" : ""}
                >
                  Discover
                </Link>
              </li>
              <li>
                <Link
                  activeClass="xx"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-112}
                  duration={500}
                  className={this.state.about == 1 ? "active" : ""}
                  onClick={this.handleClick}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  activeClass="xx"
                  to="technology"
                  spy={true}
                  smooth={true}
                  offset={-112}
                  duration={500}
                  className={this.state.technology == 1 ? "active" : ""}
                  onClick={this.handleClick}
                >
                  Technology
                </Link>
              </li>
            </ul>
          </nav>
          <a href="https://apps.apple.com/gb/app/bosch-ar/id1499809874?mt=8" target="_blank" className="appstore_badge">
            <img
              alt="Download button that leads to the App Store"
              src={require("../assets/images/appstore_badge.svg")}
            />
          </a>
        </div>
      </header>
    );
  }
}

export default Header;
