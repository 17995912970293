import React, { Component } from "react";
import classnames from "classnames";

import Phone from "../phone";

var stopScroll = true;

class ScreensSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: null,
      tappedOpacity: null,
      isAnimationOver: null,
      scrollInviteOpacity: 0,
      index: -1,
      lastTextVisible: 0
    };
  }

  observeFlags = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const index = parseInt(entry.target.dataset.index, 10);
        this.setState({
          index: index
        });
      }
    });
  };

  componentDidMount() {
    document.body.className = "first-component-body";

    this.flagsObserver = new IntersectionObserver(this.observeFlags, {
      threshold: 1.0
    });
    let flags = document.querySelectorAll(".flag");
    flags.forEach(flag => {
      this.flagsObserver.observe(flag);
    });
  }

  componentWillUnmount() {}

  panelsOver = () => {
    document.body.className = "first-component-body-canScroll";

    this.setState({
      isAnimationOver: true,
      scrollInviteOpacity: 1
    });
  };

  render() {
    const { className } = this.props;
    const {
      isAnimationOver,
      firstOpacity,
      opacity,
      tappedOpacity,
      scrollInviteOpacity,
      index
    } = this.state;

    return (
      <section
        className={classnames(
          "section screens_first_section desktop_only",
          className
        )}
        id="discover"
      >
        <div className="banner_image">
          <div
            className={classnames(
              "banner_image__panel",
              "banner_image__panel--up",
              {
                "banner_image__panel--closed": tappedOpacity
              }
            )}
            onTransitionEnd={tappedOpacity ? this.panelsOver : null}
          ></div>

          <div
            className={classnames(
              "banner_image__panel",
              "banner_image__panel--down",
              {
                "banner_image__panel--closed": tappedOpacity
              }
            )}
          ></div>
        </div>

        <Phone
          className={classnames("device_scroll", {
            "device_scroll--variant": index === 1
          })}
        >
          <div
            className="device_scroll__background"
            style={{ opacity: index !== 1 ? 1 : 0 }}
          ></div>

          <div
            className="device_scroll__background device_scroll__background--variant"
            style={{ opacity: index === 1 ? 1 : 0 }}
          ></div>

          <img
            className="title"
            alt="Bosch AR experiment"
            style={{ opacity: index >= 0 ? 0 : 1 }}
            src={require("../../assets/images/screens_device_title.svg")}
          />

          <img
            className="tap_to_start"
            alt="Tap to start"
            style={{ opacity: opacity }}
            src={require("../../assets/images/tap_to_start.svg")}
          />
          <div
            className={"tap_invite"}
            style={{ opacity: opacity }}
            onClick={() => this.setState({ opacity: 0, tappedOpacity: 1 })}
          >
            <div className="white_dot"></div>
            <div className="circle one"></div>
            <div className="circle two"></div>
            <div className="circle three"></div>
          </div>

          <img
            className="place_a_character"
            alt="Place a character"
            style={{ opacity: opacity }}
            src={require("../../assets/images/place_a_character.svg")}
          />

          {/* elements that appear after the user taps */}
          <img
            className="enjoy"
            alt="Enjoy!"
            style={{
              opacity: index >= 0 ? 0 : tappedOpacity
            }}
            src={require("../../assets/images/enjoy.svg")}
          />

          <img
            className="character"
            alt="A character coming from Bosch's art, placed in the real world"
            style={{
              opacity: index >= 0 ? 0 : tappedOpacity
            }}
            src={require("../../assets/images/bosch_character.svg")}
          />

          {/* elements that will change on scroll */}
          <img
            className="navbar"
            alt="App title and a menu icon"
            style={{
              opacity: index >= 0 && index < 3 ? 1 : 0
            }}
            src={require("../../assets/images/device_navbar.svg")}
          />

          <img
            className="lower"
            alt="Buttons and controls whithin the app"
            style={{
              opacity: index >= 0 && index < 3 ? 1 : 0
            }}
            src={require("../../assets/images/device_lower.svg")}
          />

          <div
            className={classnames("device-blur", {
              "device-blur--hidden": index < 0
            })}
          ></div>

          <img
            className="scanning"
            alt="Scanning"
            style={{
              opacity: index === 0 ? 1 : 0
            }}
            src={require("../../assets/images/scanning.svg")}
          />

          <div
            className="red_grid"
            alt="Red grid placed on real life surface"
            style={{
              opacity: index === 0 ? 1 : 0
            }}
          ></div>

          {/* second set of elements */}
          <img
            className="three_chars"
            alt="Three characters coming from Bosch's art, placed inside the real world"
            style={{ opacity: index === 1 ? 1 : 0 }}
            src={require("../../assets/images/three_chars_1.svg")}
          />

          <div
            className="dots"
            alt="Icons to select the wanted character"
            style={{ opacity: index === 1 || index === 2 ? 1 : 0 }}
          >
            <img src={require("../../assets/images/flute.svg")} alt="" />
            <img src={require("../../assets/images/bird.svg")} alt="" />
            <img src={require("../../assets/images/knight.svg")} alt="" />
            <img src={require("../../assets/images/seahorse.svg")} alt="" />
            <img src={require("../../assets/images/salamander.svg")} alt="" />
          </div>

          <img
            className="character1"
            alt="A character coming from Bosch's art, placed inside the real world"
            style={{ opacity: index === 2 ? 1 : 0 }}
            src={require("../../assets/images/character1.svg")}
          />

          <img
            className="character2"
            alt="A character coming from Bosch's art, placed inside the real world"
            style={{ opacity: index >= 3 ? 1 : 0 }}
            src={require("../../assets/images/character2.svg")}
          />

          <img
            className="cancel_button"
            alt="Button to cancel"
            style={{ opacity: index >= 3 ? 1 : 0 }}
            src={require("../../assets/images/cancel_button.svg")}
          />

          <img
            className="share_buttons"
            alt="Buttons to share"
            style={{ opacity: index >= 3 ? 1 : 0 }}
            src={require("../../assets/images/share_buttons.svg")}
          />
        </Phone>

        <div
          className={classnames("scrollable", {
            "scrollable--visible": isAnimationOver
          })}
          id="#scrollable"
        >
          <div className="flag" id="firstText" data-index="0"></div>
          <div className="flag" id="secondText" data-index="1"></div>
          <div className="flag" id="thirdText" data-index="2"></div>
          <div className="flag" id="fourthText" data-index="3"></div>
          <div className="flag" id="fifthText" data-index="4"></div>

          <div className="left">
            <div
              className={classnames("content", {
                "content--visible": index === 0
              })}
            >
              <div className="text">
                <img
                  className="sx1_title"
                  alt="Bosch AR experiment"
                  style={{ opacity: firstOpacity }}
                  src={require("../../assets/images/screens_sx1_title.svg")}
                />
                <br />
                <h2 className="sx1">Bring Bosch's art out of the canvas</h2>
              </div>
            </div>

            <div
              className={classnames("content", {
                "content--visible": index === 1
              })}
            >
              <div className="text">
                <img
                  className="sx1_title"
                  src={require("../../assets/images/character_5.svg")}
                />
              </div>
            </div>

            <div
              className={classnames("content", {
                "content--visible": index === 2
              })}
            >
              <div className="text">
                <p className="sx3">
                  Interact with the characters
                  <br />
                  by changing their size, position <br />
                  and orientation.
                </p>
              </div>
            </div>
          </div>

          <div className="right">
            <div
              className={classnames("content", {
                "content--visible": index === 0
              })}
            >
              <div className="text">
                <p className="dx1">
                  Scan the environment
                  <br />
                  around you.
                </p>
              </div>
            </div>

            <div
              className={classnames("content", {
                "content--visible": index === 1
              })}
            >
              <div className="text">
                <p className="dx2">
                  Choose between 5 different characters and bring them to life
                  around you.
                </p>
              </div>
            </div>

            <div className="content">
              <div className="text"></div>
            </div>
            <div
              className={classnames("content", {
                "content--visible": index === 3
              })}
            >
              <div className="text">
                <p className="dx4">
                  Take pictures and recordings
                  <br />
                  of your experience and share <br />
                  them with your friends<span className="exmark">!</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="scroll_invite"
          style={{
            opacity: scrollInviteOpacity && index < 4 ? 1 : 0,
            transitionDelay: index > 3 ? "0s" : null,
            transitionDuration: index > 3 ? ".48s" : null
          }}
        >
          <img
            alt="Scroll down"
            src={require("../../assets/images/scroll.svg")}
          />
        </div>
      </section>
    );
  }
}

export default ScreensSection;
